import React from "react"
import Layout from "../layouts"

import HomeIllus from "../images/home_illus.png"

export default () => (
  <Layout>
    <div className="mw-beskar mt5 center ph3 ph4-l flex items-center-l justify-between h-100">
      <div className="image w-50">
        <img src={HomeIllus} alt="people working" />
      </div>
      <div className="quote w-40">
        <h1 className="f-title">
          Hire product engineers who care for your product as much as you do!
        </h1>
      </div>
    </div>
  </Layout>
)
